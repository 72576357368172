(function() {
    var app = angular.module('pronto', ['restangular', 'ngStorage', 'ngFabForm', 'angucomplete-alt', 'oitozero.ngSweetAlert']);

    app.config(function(RestangularProvider, $interpolateProvider) {
        RestangularProvider.setBaseUrl('/api');
        $interpolateProvider.startSymbol('[[');
        $interpolateProvider.endSymbol(']]');
    });

    app.controller('OrderController', function($scope, $rootScope, $timeout, $http, $q, $localStorage, Restangular, SweetAlert) {
        var categoriesList = Restangular.all('category').getList();
        var areasList = Restangular.all('area').getList();

        $scope.showCategories = false;
        $scope.categories = categoriesList.$object;
        $scope.areas = areasList.$object;
        $scope.selectedCategory = null;
        $scope.currentProduct = null;
        $scope.currentProductOriginal = null;
        $scope.deliveryFee = 0;
        $scope.$storage = $localStorage.$default({
            customer: {},
            order: []
        });

        $http.get('/api/working-hour/is-opened').then(function(result) {
            if(!result.data.opened) {
                SweetAlert.swal({
                    title: 'Dieses Restaurant ist derzeit geschlossen.',
                    text: 'Sie können dennoch eine Vorbestellung tätigen.<br>Geben Sie unter "Sonstiges" bitte Ihren Wunschliefertermin an.',
                    html: true,
                    type: 'info'
                });
            }
        });

        $scope.$watchGroup(['currentProduct', 'showCart', 'showOrderForm'], function() {
            $rootScope.noScroll = ($scope.currentProduct || $scope.showCart || $scope.showOrderForm);
            console.log($rootScope.noScroll);
        });

        $q.all([categoriesList, areasList]).then(function() {
            var cols = 3;
            var l = $scope.categories.length;
            var m = Math.ceil(l / cols);

            for(var i in $scope.categories) {
                if($scope.categories[i]) {
                    $scope.categories[i].sizes |= 0;
                }
            }

            for(var i in $scope.areas) {
                if($scope.areas[i]) {
                    $scope.areas[i].name = $scope.areas[i].zip + ' - ' + $scope.areas[i].name;
                }
            }

            $scope.categories1 = [];
            $scope.categories2 = [];
            $scope.categories3 = [];

            for(var i = 0; i < m; i++) {
                $scope.categories1[i] = $scope.categories[i];

                if(i + m < l) {
                    $scope.categories2[i] = $scope.categories[i + m];
                }

                if(i + 2 * m < l) {
                    $scope.categories3[i] = $scope.categories[i + 2 * m];
                }
            }

            $scope.setCategory($scope.categories[0]);
            $scope.updateDeliveryFee();
            $scope.loaded = true;
        });

        /**
         * @param category
         */
        $scope.setCategory = function(category) {
            $scope.selectedCategory = category;
            $scope.showCategories = false;

            if(!category.products) {
                category.products = category.all('product').getList().$object;
            }
        };

        /**
         * @returns {number}
         */
        $scope.getOrderPrice = function() {
            return $scope.deliveryFee + $scope.getCartPrice();
        };

        /**
         * @returns {number}
         */
        $scope.getCartPrice = function() {
            var price = 0.0;
            var order = $scope.$storage.order;

            for(var i in order) {
                price += $scope.getProductTotalPrice(order[i]);
            }

            return price;
        };

        $scope.checkMimimumOrder = function() {
            return false;
        };

        /**
         * @param product
         */
        $scope.showExtraPopup = function(product, add) {
            if(!product) {
                return $scope.closeExtraPopup();
            }

            if(add) {
                $scope.currentProductOriginal = null;
            } else {
                $scope.currentProductOriginal = product;
            }

            $scope.variantError = false;
            $scope.currentProduct = _.clone(product, true);
            $scope.currentProduct.showCart = add | false;
        };

        /**
         */
        $scope.closeExtraPopup = function() {
            $scope.currentProduct = null;
        };

        /**
         */
        $scope.doOrder = function() {
            if($scope.$storage.order.length == 0) {
                $scope.noProducts = true;
                return;
            } else {
                $scope.noProducts = false;
            }

            if($scope.tos) {
               $scope.noTos = false;
            } else {
                $scope.noTos = true;
                return;
            }

            if($scope.$storage.area) {
                $scope.noAreaSelected = false;
            } else {
                $scope.noAreaSelected = true;
                return;
            }

            if($scope.minimumOrder > $scope.getCartPrice()) {
                $scope.belowMinimum = true;
                return;
            } else {
                $scope.belowMinimum = false;
            }

            $scope.loaded = false;
            $scope.$storage.customer.zipCode = $scope.$storage.area.zip;

            $http.post('/api/order', $scope.$storage).then(function(response) {
                $scope.$storage.order = [];
                document.location.href = "/lieferservice/erfolg";
            });

            return true;
        };

        /**
         * @param product
         * @param size
         */
        $scope.selectProduct = function(product, size) {
            if(!product) {
                return;
            }

            var prod = _.clone(product, true);

            prod.size = size | 0;
            prod.category_id |= 0;

            if($scope.getProductPrice(prod) == 0) {
                return;
            }

            if($scope.hasProductExtras(prod)) {
                $scope.showExtraPopup(prod, true);
            } else {
                $scope.addToCart(prod);
            }
        };

        /**
         * @param product
         */
        $scope.addToCart = function(product) {
            if($scope.hasProductVariants(product)) {
                if(!product.variant) {
                    $scope.variantError = true;
                    return;
                }
            }


            SweetAlert.swal({
                title: '',
                text: product.name + ' hinzugefügt',
                type: 'success',
                showConfirmButton: false,
                timer: 800
            });

            $scope.variantError = false;
            $scope.$storage.order.push(product);
            $scope.closeExtraPopup();
        };

        /**
         */
        $scope.saveCurrentProductChanges = function() {
            if($scope.currentProductOriginal) {
                $scope.currentProductOriginal.selectedIngredients = [];
                _.merge($scope.currentProductOriginal, $scope.currentProduct);
            }

            $scope.closeExtraPopup();
        };

        /**
         * @param product
         */
        $scope.removeProduct = function(product) {
            var index = $scope.$storage.order.indexOf(product);

            if (index > -1) {
                $scope.$storage.order.splice(index, 1);
            }
        };

        /**
         * @param product
         * @returns {boolean}
         */
        $scope.hasProductExtras = function(product) {
            if(!product) {
                return false;
            }

            var category = $scope.getProductCategory(product);

            if(!category) {
                return false;
            }

            if(category.ingredients.length > 0) {
                return true;
            }

            if(category.variants.length > 0) {
                return true;
            }

            return false;
        };

        /**
         * @param product
         * @returns {boolean}
         */
        $scope.hasProductVariants = function(product) {
            if(!product) {
                return false;
            }

            var category = $scope.getProductCategory(product);

            if(!category) {
                return false;
            }

            if(category.variants.length > 0) {
                return true;
            }

            return false;
        };

        /**
         * @param product
         * @returns {boolean}
         */
        $scope.hasProductSizes = function(product) {
            if(!product) {
                return false;
            }

            for(var i in $scope.categories) {
                if($scope.categories[i].id == product.category_id) {
                    return !!($scope.categories[i].sizes);
                }
            }

            return false;
        };

        /**
         * @param product
         * @returns {*}
         */
        $scope.getProductCategory = function(product) {
            for(var i = 0; i < $scope.categories.length; i++) {
                if ($scope.categories[i].id == product.category_id) {
                    return $scope.categories[i];
                }
            }

            return null;
        };

        /**
         * @param product
         * @returns {string}
         */
        $scope.getProductVariantName = function(product) {
            if(!product) {
                return '';
            }

            if(!product.variant) {
                return '';
            }

            product.variant |= 0;

            var variants = $scope.getProductVariants(product);

            for(var i = 0; i < variants.length; i++) {
                if((variants[i].id | 0) == product.variant) {
                    return variants[i].name;
                }
            }

            return '';
        };

        /**
         * @param product
         * @returns {*}
         */
        $scope.getProductIngredients = function(product) {
            if(!product) {
                return null;
            }

            if('ingredients' in product) {
                return product.ingredients;
            }

            var category = $scope.getProductCategory(product);

            if(!category) {
                return null;
            }

            product.ingredients = category.ingredients;

            return product.ingredients;
        };

        /**
         * @param product
         * @returns {*}
         */
        $scope.getProductVariants = function(product) {
            if(!product) {
                return null;
            }

            if('variants' in product) {
                return product.variants;
            }

            var category = $scope.getProductCategory(product);

            if(!category) {
                return null;
            }

            product.variants = category.variants;

            return product.variants;
        };

        /**
         * @param product
         * @returns {string}
         */
        $scope.getProductSizeName = function(product) {
            if(!$scope.hasProductSizes(product)) {
                return '';
            }

            switch(product.size) {
                case 0: return 'Normal';
                case 1: return 'Gross';
                case 2: return 'Family';
                case 3: return 'Party';
                default: return '';
            }
        };

        /**
         * @param product
         * @returns {*}
         */
        $scope.getProductTotalPrice = function(product) {
            if(!product) {
                return 0;
            }

            return $scope.getProductPrice(product) + $scope.getProductIngredientsPrice(product);
        };

        /**
         * @param product
         * @returns {number}
         */
        $scope.getProductPrice = function(product) {
            if(!product) {
                return 0;
            }

            switch(product.size|0) {
                case 1: return parseFloat(product.price_size1);
                case 2: return parseFloat(product.price_size2);
                case 3: return parseFloat(product.price_size3);
                default: break;
            }

            return parseFloat(product.price);
        };

        /**
         * @param product
         * @returns {number}
         */
        $scope.getProductIngredientsPrice = function(product) {
            if(!product) {
                return 0;
            }

            if(product && !('selectedIngredients' in product)) {
                product.selectedIngredients = [];
            }

            var price = 0;

            for (var i in product.selectedIngredients) {
                price += $scope.getProductIngredientPrice(product, product.selectedIngredients[i]);
            }

            return price;
        };

        /**
         * @param product
         * @param ingredient
         * @returns {number}
         */
        $scope.getProductIngredientPrice = function(product, ingredient) {
            var price_key;

            switch(product.size | 0) {
                case 1:
                    price_key = 'price_size1';
                    break;
                case 2:
                    price_key = 'price_size2';
                    break;
                case 3:
                    price_key = 'price_size3';
                    break;
                default:
                    price_key = 'price';
            }

            return parseFloat(ingredient[price_key]);
        };

        /**
         * @param product
         * @param ingredient
         * @returns {boolean}
         */
        $scope.isProductIngredientSelected = function(product, ingredient) {
            if(!product) {
                return false;
            }

            if(!('selectedIngredients' in product)) {
                product.selectedIngredients = [];
            }

            for(var i in product.selectedIngredients) {
                if(ingredient.id == product.selectedIngredients[i].id) {
                    return true;
                }
            }

            return false;
        };

        /**
         * @param product
         * @param ingredient
         */
        $scope.toggleProductIngredient = function(product, ingredient) {
            for(var i in product.selectedIngredients) {
                if(ingredient.id == product.selectedIngredients[i].id) {
                    product.selectedIngredients.splice(i, 1);
                    return;
                }
            }

            product.selectedIngredients.push(ingredient);
        };

        /**
         */
        $scope.finishOrderResponsive = function () {
            if($scope.$storage.order.length == 0) {
                return;
            }

            $scope.showCart = false;
            $scope.showOrderForm = true;
            $scope.closeExtraPopup();
        };

        /**
         */
        $scope.closeOrderForm = function() {
            $scope.showOrderForm = false;
        };

        /**
         */
        $scope.updateDeliveryFee = function() {
            $scope.deliveryFee = 0;
            $scope.minimumOrder = 0;

            if($scope.$storage.area) {
                $scope.deliveryFee = parseFloat($scope.$storage.area.fee);
                $scope.minimumOrder = parseFloat($scope.$storage.area.minimum);
            }
        };

        /**
         * @param area
         */
        $scope.setArea = function(area) {
            if(area) {
                $scope.$storage.area = area.originalObject;
            } else {
                $scope.$storage.area = null;
            }

            $scope.updateDeliveryFee();
        };

        /**
         */
        $scope.checkAreaInput = function() {
            $timeout(function() {
                if(!$scope.$storage.area) {
                    $scope.$broadcast('angucomplete-alt:clearInput');
                }
            }, 100);
        };

        /**
         * dirty
         */
        $scope.focusTownSelection = function() {
            $timeout(function() {
                document.getElementById('town_value').focus();
            }, 0);
        };
    });

    app.directive('slideToggle', function() {
        return {
            restrict: 'A',
            scope:{
                isOpen: "=slideToggle"
            },
            link: function(scope, element, attr) {
                var slideDuration = parseInt(attr.slideToggleDuration, 10) || 200;
                scope.$watch('isOpen', function(newVal,oldVal){
                    if(newVal !== oldVal){
                        element.stop().slideToggle(slideDuration);
                    }
                });
            }
        };
    });

    app.filter('unsafe', function($sce) {
        return function(val) {
            return $sce.trustAsHtml(val);
        };
    });
})();